import React from "react"

import Button from "../../shared/Button"
import Heading from "../../shared/Heading"
import "./Hero.scss"
export default function Hero({ btnText, img, children, text, social, id }) {
  return (
    <div className="pd-hero">
      <div className="hero-bg" id={id}>
        <div className="container position-relative">
          <div className="row">
            <div
              className="col-lg-6 hero-text-column"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-anchor-placement="top-bottom"
            >
              <div className="line-wrapper">
                <p className="text">Product Design</p>
              </div>
              <Heading variant="58" className="header">
                <h1 className="p-1">
                  We don't design products, we design experiences
                </h1>
                {/* <p className="p-2">Agency</p> */}
              </Heading>

              <div className="hero-text">
                We're product people at heart. We love creating products that
                make people happy. That's why we take care of building beautiful
                and usable products for you - providing you with a great user
                experience from the get-go!
              </div>
              <div className="hero-text"></div>
              <div className="button text-capitalize">
                <Button
                  text={btnText}
                  variant="fill"
                  href="/contact"
                  className="text-capitalize"
                />
              </div>
            </div>
            <div className="col-lg-6 img-hero">
              <img
                src="/ProductDesign/hero.svg"
                alt="img-hero"
                className="img-fluid"
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
