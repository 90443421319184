import React, { useState, useEffect } from "react"
import Hero from "./Hero"
import "./ProductDesign.scss"
import Head from "../utils/Head"
import {
  ProductDesignItemsSliders,
  updatedProductDesign,
} from "../shared/ModalSlider/data"
import Clutch from "../Homepage/Clutch"
const ModalSlider = React.lazy(() => import("../shared/ModalSlider"))
const ImageSwiper = React.lazy(() => import("./WorkSwiper"))
const DesignDefinition = React.lazy(() => import("./DesignDefinition"))
const DesignSprints = React.lazy(() => import("./DesignSprints"))
const PartnersSection = React.lazy(() =>
  import("./PartnerSection/PartnersSection")
)
const WorkSwiper = React.lazy(() => import("../Homepage/WorkSwiper"))
const ClientSection = React.lazy(() => import("./ClientSection"))
const Reviews = React.lazy(() => import("../Homepage/Reviews"))
const ServicesSection = React.lazy(() => import("./ServicesSection"))
const Accordian = React.lazy(() => import("./FAQs/Accordian"))
const ContactUs = React.lazy(() => import("./Footer/ContactUs"))

function ProductDesign() {
  const [showReview, setShowReview] = useState(false)
  useEffect(() => {
    setShowReview(true)
  }, [])
  return (
    <div>
      <Head
        metaTitle="Product Design and Development Services - CodeFulcrum"
        title="Product design and development company , digital product design"
        desc="Our product design services will help you take your product from concept to market. We take a holistic approach to product design and development, incorporating user experience, engineering, and marketing."
      />

      <Hero
        id="top"
        header="Design Unique And Creative Digital Products Tailored To Your Needs"
        text1="We provide product design and strategy services to companies of
        all shapes and sizes"
        text2="– Tell us what, and we’ll show you how."
        btnText="LET’S SCHEDULE A CALL"
        scroll="#design-pics"
      />
      <React.Suspense fallback={<div />}>
        <ImageSwiper />
      </React.Suspense>
      <React.Suspense fallback={<div />}>
        <DesignDefinition />
      </React.Suspense>
      <React.Suspense fallback={<div />}>
        <ModalSlider
          page="productdesign"
          data={updatedProductDesign}
          title={"Here’s how we design your vision"}
          description={`Through inventive and intuitive design, we allow your brand to stand out from the crowd by leveraging your goals to craft a creative vision.`}
        />
      </React.Suspense>
      <React.Suspense fallback={<div />}>
        <DesignSprints />
      </React.Suspense>

      <React.Suspense fallback={<div />}>
        <PartnersSection />
      </React.Suspense>
      <React.Suspense fallback={<div />}>
        <WorkSwiper />
      </React.Suspense>
      <React.Suspense fallback={<div />}>
        <ClientSection />
      </React.Suspense>
      {showReview && (
        <React.Suspense fallback={<div />}>
          {/* <Reviews /> */}
          <Clutch />
        </React.Suspense>
      )}
      <React.Suspense fallback={<div />}>
        <ServicesSection />
      </React.Suspense>
      <React.Suspense fallback={<div />}>
        <Accordian />
      </React.Suspense>
      <React.Suspense fallback={<div />}>
        <ContactUs type="ProductDesign" />
      </React.Suspense>
    </div>
  )
}

export default ProductDesign
