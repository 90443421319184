import React, { useEffect } from "react"
import Layout from "../components/Layout"
import { Helmet } from "react-helmet"
import ProductDesign from "../components/ProductDesign"

export default function ServicesPage() {
  return (
    <Layout>
      <Helmet>
        <link rel="canonical" href="https://codefulcrum.com/product-design/" />
      </Helmet>

      <ProductDesign />
    </Layout>
  )
}
